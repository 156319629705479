import React, { Component } from 'react';
import {Ajaxtest} from '../components/ajaxtest';
import {Chart} from '../components/Chart';
import {Auth} from '../components/Auth';


class Project extends Component {

	constructor(props) {
	    super(props);
      console.log('user id: ' + Auth.userId);
      this.state = { 
        userId: Auth.userId,
        userName: Auth.name,
        userLastName: Auth.lastName,
        project: [],
        keywords: [],
        inputKeyword: '',
        posts: []
      };

      this.handleKeywordChange = this.handleKeywordChange.bind(this);
      this.addKeyword = this.addKeyword.bind(this);
      this.removeKeyword = this.removeKeyword.bind(this);
      this.handleClick = this.handleClick.bind(this);
      
	}

  getProjectKeywords(){
    fetch("http://api.sentilytics.com/api/keywords/project", 
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify({
          Auth,
          projectId: this.props.match.params.id
        })
       })
      .then(res => res.json())
      .then(
        (result) => {
          console.log('test: ');
          console.log(result);
          this.setState({
            keywords: result.data.keywords
          });
          console.log(this.state.keywords);
          this.getProjectPosts(this.state.keywords);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
  }

  getAllKeywords(){
    fetch("http://api.sentilytics.com/api/keywords/all", 
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify({
          Auth,
          projectId: this.props.match.params.id
        })
       })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            keywords: result.data
          });


          //console.log(this.state.keywords);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
  }

  getProjectPosts(keywords){

    let k = [];
    keywords.map(function(item, index){
      k.push(item.name);
    });
    fetch("http://api.sentilytics.com/cron/get_posts_preview", 
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify({
          keywords: k,
          negativeKeywords: ['macka','termos']
        })
       })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            posts: result.data
          });
          //console.log(this.state.keywords);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
  }

  componentDidMount() {

    //this.getAllKeywords();

    this.getProjectKeywords();

    

    fetch("http://api.sentilytics.com/api/project/get", 
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify({
          Auth,
          id: this.props.match.params.id
        })
       })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            project: result.data
          });
          console.log(this.state.project);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )


  }

  handleClick(event){
    console.log('test handle click');
  }

  handleKeywordChange(event){
    this.setState({inputKeyword: event.target.value});
    console.log(this.state);
  }

  addKeyword(event) {
    fetch("http://api.sentilytics.com/api/keywords/add", 
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify({
          Auth,
          projectId: this.props.match.params.id,
          keyword: this.state.inputKeyword
          
        })
       })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.getProjectKeywords();
          // this.setState({
          //   projects: result.data
          // });
          //console.log(this.state.projects);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      );

     event.preventDefault();
  }

  removeKeyword(keywordId) {

    console.log(this.props.match.params.id);
    fetch("http://api.sentilytics.com/api/keywords/remove", 
      {
        method: 'post',
        headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: JSON.stringify({
          Auth,
          projectId: this.props.match.params.id,
          keywordId: keywordId
          
        })
       })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          this.getProjectKeywords();
          // this.setState({
          //   projects: result.data
          // });
          //console.log(this.state.projects);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      );

     //event.preventDefault();
  }

  

  render() {

    return (
      <div>
        <h1>{this.state.project.name}</h1>
        <p>{this.state.project.description}</p>
        <p>Project id: {this.props.match.params.id}</p>
        <h3>Keywords:</h3>
        {this.state.keywords.map(function(item, index){
          return(
              <div className="keyword" key={index} >
                {item.name}
                <button className="keyword-delete-button" onClick={() => this.removeKeyword(item.id)} key={item.id} >X</button>
              </div>
            );
        }, this)}

        <h3>Posts:</h3>
        {this.state.posts.map(function(post, index){
          return(
              <div className="post" key={index} >
                {post.fullText}
              </div>
            );
        }, this)}

        <form onSubmit={this.addKeyword}>
        <label>
          <br />Add Keyword:
          <br /><input type="text" value={this.state.inputKeyword} onChange={this.handleKeywordChange} />
        </label>
        <input type="submit" value="Submit" />
      </form>
      </div>
      
    )
  }
}

export default Project;