import React, { Component } from 'react';
import '../select.css';

var interval;
var prevSelected = false;

class Select extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            selected: null,
            selectedIndex: null,
            visible: false,
            filter: ''
        }
        // this.myRef = [];
        this.ref = React.createRef();
        this.container = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.id) {
            if(this.props.selected != prevProps.selected){

                if(typeof this.props.selected == 'number'){
                    this.props.options.map(item => {
                        if(this.props.selected == item[this.props.id]){
                            this.setSelected(item);
                        }
                    })
                }
                if( typeof this.props.selected == 'string' && this.props.value){
                    this.props.options.map(item => {
                        if(this.props.selected == item[this.props.value]){
                            this.setSelected(item);
                        }
                    })
                }

                if(typeof this.props.selected == 'object'){
                    this.setSelected(this.props.selected);
                }
            }
        }


        if (prevProps.options !== this.props.options) {
            this.setState({options: this.props.options});
        }


        // filter
        if(this.state.filter !== prevState.filter){
            if(this.props.async){
                clearInterval(interval);
                    
                    this.setState({options: [{[this.props.id]: null, [this.props.value]: 'ucitavanje...'}]})
                    interval = setTimeout(()=>{
                        
                        if(this.props.onFilter && this.state.filter){
                            this.props.onFilter(this.state.filter);
                        }
                        
                    }, this.props.filterTimeout ? this.props.filterTimeout : 500);

            }else{
                if(this.state.filter){
                    let o = [];
                    let temp = [];
                    this.props.options.map(item => {
                        o.push(item);
                    })
                    o.map(item => {
                        if(item[this.props.value].substring(0, this.state.filter.length).toLowerCase() == this.state.filter.toLowerCase()){
                            temp.push(item);
                        }
                    });
                    this.setState({options: temp});
                }else{
                    this.setState({
                        options: this.props.options,
                        visible: true,
                        selected: null
                    })
                }
            }
        }
        
    }

    componentDidMount(){

        this.setState({options: this.props.options});

        if(typeof this.props.selected == 'number'){
            this.props.options.map(item => {
                if(this.props.selected == item[this.props.id]){
                    this.setSelected(item);
                }
            })
        }
        if( typeof this.props.selected == 'string' && this.props.value){
            this.props.options.map(item => {
                if(this.props.selected == item[this.props.value]){
                    this.setSelected(item);
                }
            })
        }

        if(typeof this.props.selected == 'object'){
            this.setSelected(this.props.selected);
        }
    }

    setSelected=(value)=>{
        this.setState({filter: '', selected: value});
    }

    changeOptionMouse=()=>{
        this.setState({visible: true});
    }

    changeOption=(event)=>{

        if([9,16,17].includes(event.keyCode)){
            
            return;
        }

        if(event.keyCode == 27){
            event.target.blur();
            return;
        }

         let wasVisible = this.state.visible;
         if(![13, 27].includes(event.keyCode)){
            this.setState({visible: true});
         }

        if(![38, 40, 13].includes(event.keyCode)){
            this.setState({selected: null});
        }
        if(!wasVisible && !(event.keyCode == 46 || event.keyCode == 8)){
            return;
        }
        

        if(event.keyCode == 38){
            if(this.state.selectedIndex > 0){
                this.setState({selectedIndex: this.state.selectedIndex - 1}, ()=>{
                    this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                })
                
            }
            return;
        }
        if(event.keyCode == 40){
            if(this.state.selectedIndex == null){
                this.setState({selectedIndex: 0});
                return;
            }
            if(this.state.selectedIndex < this.state.options.length - 1){
                this.setState({selectedIndex: this.state.selectedIndex + 1}, ()=>{
                    this.ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

                })
            }
            return;
        }
        
        if(event.keyCode == 46 || event.keyCode == 8){
            this.setState({visible: false, selected: null, filter: ''}, ()=>{
                this.props.onSelect(null);
            })
            return;
        }

        if(event.keyCode == 13){
            if(this.state.visible){
                this.setState({visible: false, selected: this.state.options[this.state.selectedIndex], filter: ''}, ()=>{
                    if(this.state.selected){
                        this.props.onSelect(this.state.selected[this.props.id]);
                    }else{
                        this.props.onSelect(null);
                    }
                })
                
            }
            event.target.blur();
            return;

        }       
    }

    setWithMouse=(index)=>{
        this.setState({visible: false, selected: this.state.options[index], filter: ''}, ()=>{
            this.props.onSelect(this.state.selected[this.props.id]);
        })
    }

    handleFilter=(event)=>{
        this.setState({filter: event.target.value});
    }


    render(){
        return(
            <div className="react-select-vd" >
                <div className="react-select-vd-input-container" onKeyDown={this.changeOption} onClick={this.changeOptionMouse} >
                    <input placeholder={this.state.selected ? null : this.props.placeholder} onBlur={()=>{this.setState({visible: false})}}  onChange={this.handleFilter} value={this.state.filter} />
                    {this.state.selected &&
                        <p style={{pointerEvents: 'none'}} className="react-select-vd-selected">{this.state.selected[this.props.value]}</p>
                    }
                    <svg className={this.state.visible ? "arrow" : "arrow closed"} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
                </div>
                {this.state.visible &&
                    <div ref={this.container} className="react-select-vd-results" >
                        {this.state.options.map((item, index) => {
                            return(
                                <div key={`opt-${index}`} onMouseDown={()=>{this.setWithMouse(index)}} ref={index == this.state.selectedIndex ? this.ref : null} className={index == this.state.selectedIndex ? 'react-select-vd-result selected' : 'react-select-vd-result'} data-key={item[this.props.key]} >{item[this.props.value]}</div>
                            )
                        })}
                    </div>
                }
            </div>
        )
        }

}

export default Select


// export default function Select(props){

//     const [options, setOptions] = useState(props.options);
//     const [selected, setSelected] = useState();
//     const [selectedIndex, setSelectedIndex] = useState();
//     const [visible, setVisible] = useState(false);
//     const [filter, setFilter] = useState('');


//     const ref = useRef(null);
//     const container = useRef(null);

//     useEffect(()=>{
//         let options = props.options;
//         setOptions(options);
        
//         if(props.selected){
//             if(typeof props.selected == 'number'){
//                 props.options.map(item => {
//                     if(props.selected == item[props.id]){
//                         setSelected(item);
//                     }
//                 })
//             }
//             if( typeof props.selected == 'string' && props.value){
//                 props.options.map(item => {
//                     if(props.selected == item[props.value]){
//                         setSelected(item);
//                     }
//                 })
//             }

//             if(typeof props.selected == 'object'){
//                 console.log('obkect selected')
//                 setSelected(props.selected);
//             }
//         }else{
//             if(!props.async){
//                 setSelected(null);
//             }
//         }
//     }, [props.options, props.selected]);

//     useEffect(()=>{
        
//             setFilter('');
//             console.log('selected', selected);
//             setVisible(false);
//         if(selected != prevSelected){
//             prevSelected = selected;
            
//             if(props.onSelect){
//                 if(selected && selected != props.selected){
//                     props.onSelect(selected[props.id]);
//                     console.log('sel true')
//                 }else{
//                     //console.log('sel false')
//                     //props.onSelect(null); 

                    
//                 }
//             }
//         }
        
//     }, [selected]);

//     useEffect(()=>{
//         console.log('f', filter);
//         if(filter){
//             console.log('has filter');
//         }
//         if(props.async){
//             clearInterval(interval);
                
//                 setOptions([{[props.id]: null, [props.value]: 'ucitavanje...'}]);
//                 interval = setTimeout(()=>{
                    
//                     if(props.onFilter && filter){
//                         props.onFilter(filter);
//                         console.log('aaa');
//                         console.log('filter changed async');
//                     }
                    
//                 }, props.filterTimeout ? props.filterTimeout : 500);

//         }else{
//             console.log('filter test');
//             if(filter){
//                 let o = [];
//                 let temp = [];
//                 console.log('filter changed');
//                 props.options.map(item => {
//                     o.push(item);
//                 })
//                 o.map(item => {
//                     if(item[props.value].substring(0, filter.length).toLowerCase() == filter.toLowerCase()){
//                         temp.push(item);
//                     }
//                 });
//                 console.log('filter options');
//                 setOptions(temp);
//             }else{
//                 setOptions(props.options);
//                 setVisible(true);
//                 setSelected(null);
//             }
//         }
//         // if(!filter){
//         //     props.onSelect(null);
//         // }
        
//     }, [filter])

//     useEffect(()=>{
//         if(container.current && ref.current){
//             if(ref.current){
//                 if(ref.current.offsetTop > container.current.clientHeight || ref.current.offsetTop < container.current.scrollTop){
//                     ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
//                 }
//             }
//         }
//       }, [selectedIndex]);

//     const changeOption=(event)=>{
//         console.log('event pressed', event.keyCode);
//         setVisible(true);
//         //setSelected(null);

//         if(event.keyCode == 38){
//             if(selectedIndex > 0){
//                 setSelectedIndex(selectedIndex - 1);
//             }
//             return;
//         }
//         if(event.keyCode == 40){
//             if(selectedIndex == null){
//                 setSelectedIndex(0);
//                 return;
//             }
//             if(selectedIndex < options.length - 1){
//                 setSelectedIndex(selectedIndex + 1);
//             }
//             return;
//         }
        
//         if(event.keyCode == 46 || event.keyCode == 8){
//             setVisible(false);           
//             setSelected(null);
//             setFilter('');
//             console.log('delete');
//         }

//         if(event.keyCode == 13){

//             setVisible(false);           
//             setSelected(options[selectedIndex]);
//             setFilter('');
//             return;

//         }       
//     }

//     const changeOptionMouse=()=>{
//         //setSelected(null);
//         setVisible(true);
//         //props.onSelect(null);
//     }

//     const handleFilter=(event)=>{
//         setFilter(event.target.value);
//     }

//     return(
//         <div className="react-select-vd" >
//             <div className="react-select-vd-input-container" onKeyDown={changeOption} onClick={changeOptionMouse} >
//                 <input placeholder={selected ? null : props.placeholder} onBlur={()=>{setVisible(false)}}  onChange={handleFilter} value={filter} />
//                 {selected &&
//                     <p style={{pointerEvents: 'none'}} className="react-select-vd-selected">{selected[props.value]}</p>
//                 }
//                 <svg className={visible ? "arrow" : "arrow closed"} focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg>
//             </div>
//             {visible &&
//                 <div ref={container} className="react-select-vd-results" >
//                     {options.map((item, index) => {
//                         return(
//                             <div onMouseDown={()=>{setFilter(''); setSelected(options[index])}} ref={index == selectedIndex ? ref : null} className={index == selectedIndex ? 'react-select-vd-result selected' : 'react-select-vd-result'} data-key={item[props.key]} >{item[props.value]}</div>
//                         )
//                     })}
//                 </div>
//             }
//         </div>
//     )
// }