import React, { Component } from 'react';
import {Bar} from 'react-chartjs-2';

export class Chart extends Component {

	constructor(props) {
		super(props);
		this.state = {
			chartData: {
				labels: ['Boston','London','Beograd','Nis','Novi Sad'],
				datasets: [
				{
					label: 'Population',
					data:[
					333333,
					444444,
					534536,
					245343,
					335526
					],
					backgroundColor: ['rgba(255,33,55,150)']
				},
				{
					label: 'Broj Stanovnika',
					data:[
					333333,
					444444,
					333333,
					444444,
					534536
					],
					backgroundColor: ['rgb(244, 137, 36)','rgb(244, 137, 36)','rgb(244, 137, 36)']
				}
				]
			}
		}
	}
	render(){
		return(
			<div className="chart">
			<Bar data={this.state.chartData}
			//width={100}
			height={100}
				options={{maintainAspectRation: false}}
			/>
			</div>
		)
	}
}

//export default Chart;