import React from "react";
// Import Highcharts
import Highcharts from "highcharts";
import wordCloud from "highcharts/modules/wordcloud.js";
//import HighchartsReact from "./HighchartsReact.js";
import HighchartsReact from "highcharts-react-official";
import Service from '../components/Service';
import Select from '../components/Select';

wordCloud(Highcharts);




Highcharts.seriesTypes.wordcloud.prototype.deriveFontSize = function(
  relativeWeight
) {
  var maxFontSize = 25;
  // Will return a fontSize between 0px and 25px.
  return Math.floor(maxFontSize * relativeWeight);
};

const text =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum erat ac justo sollicitudin, quis lacinia ligula fringilla.",
  lines = text.split(/[,\. ]+/g),
  data = Highcharts.reduce(
    lines,
    function(arr, word) {
      var obj = Highcharts.find(arr, function(obj) {
        return obj.name === word;
      });
      if (obj) {
        obj.weight += 1;
      } else {
        obj = {
          name: word,
          weight: Math.ceil(Math.random() * 50)
        };
        arr.push(obj);
      }
      console.log('arr', arr);
      return arr;
    },
    []
  );

const options = {
  series: [
    {
      type: "wordcloud",
      data: data
    }
  ]
};

class WordCloud extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        projects: [],
        selectedProject: null,
        project: {name: null, description: null},
        options: {
            title: {
                text: 'Title'
            },
        
            subtitle: {
                text: 'Results of sentiment analisys'
            },
            series: [
              {
                type: "wordcloud",
                data: []
              }
            ]
          }
    }
  }

  getProjects = () => {
    Service.get('/projects/getProjects', {}, (res)=>{
      this.setState({projects: res.data}, ()=>{
      });
    });
  }

  selectProject=(projectId)=>{
    console.log(projectId);
    let project = {};
    this.state.projects.map(item=>{
      if(item.id == projectId){
        project = item;
      }
    })

    // let options = this.state.options;
    // options.title.text = project.name;
    this.setState({selectedProject: project, options: options}, ()=>{
      this.getProject();
    })
  }

  getProject=()=>{
    Service.get('/entities/getEntitySentimentTop100', {projectIds: [this.state.selectedProject.id]}, (res)=>{

      var makeScale = function (domain, range) {
          var minDomain = domain[0];
          var maxDomain = domain[1];
          var rangeStart = range[0];
          var rangeEnd = range[1];
      
          return (value) => {
              return rangeStart + (rangeEnd - rangeStart) * ((value - minDomain) / (maxDomain - minDomain));
          }
      };
      /**
       * Find min and max weight using reduce on data array
       */
      var minWeight = data.reduce((min, word) =>
          (word.weight < min ? word.weight : min),
          data[0].weight
      );
      var maxWeight = data.reduce((max, word) =>
          (word.weight > max ? word.weight : max),
          data[0].weight
      );
      var maxColor = data.reduce((max, word) =>
          (word.average_sentiment > max ? word.average_sentiment : max),
          data[0].average_sentiment
      );
      var minColor = data.reduce((min, word) =>
          (word.average_sentiment < min ? word.average_sentiment : min),
          data[0].average_sentiment
      );
      var scale = makeScale([minWeight, maxWeight], [0.8, 1]);
      var scaleSize = makeScale([minWeight, maxWeight], [36, 100]);
      var scaleColor = makeScale([minColor, maxColor], [0, 150]);
      /**
       * creating a new, scaled data array
       */


      var scaledData = res.data.map(word =>(

        { name: word.name, weight: word.weight, color: `rgba(${word.average_sentiment < 0 ? 200 : 0},${word.average_sentiment >= 0 ? 200 : 0},0,${(Math.abs(word.average_sentiment) * .8) + .2})` }
        )
      );

      let options = {}
      options.series = [{
          type: "wordcloud",
          data: scaledData,
          name: "sentiment",
          rotation: {
              from: 0,
              to: 0
          }
          }
      ]
    this.setState({options: options});
  })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getProjects();
    // Service.get('/entities/getEntitySentimentTop100', {projectIds: [20]}, (res)=>{

    //     var makeScale = function (domain, range) {
    //         var minDomain = domain[0];
    //         var maxDomain = domain[1];
    //         var rangeStart = range[0];
    //         var rangeEnd = range[1];
        
    //         return (value) => {
    //             return rangeStart + (rangeEnd - rangeStart) * ((value - minDomain) / (maxDomain - minDomain));
    //         }
    //     };
    //     /**
    //      * Find min and max weight using reduce on data array
    //      */
    //     var minWeight = data.reduce((min, word) =>
    //         (word.weight < min ? word.weight : min),
    //         data[0].weight
    //     );
    //     var maxWeight = data.reduce((max, word) =>
    //         (word.weight > max ? word.weight : max),
    //         data[0].weight
    //     );
    //     var maxColor = data.reduce((max, word) =>
    //         (word.average_sentiment > max ? word.average_sentiment : max),
    //         data[0].average_sentiment
    //     );
    //     var minColor = data.reduce((min, word) =>
    //         (word.average_sentiment < min ? word.average_sentiment : min),
    //         data[0].average_sentiment
    //     );
    //     var scale = makeScale([minWeight, maxWeight], [0.8, 1]);
    //     var scaleSize = makeScale([minWeight, maxWeight], [36, 100]);
    //     var scaleColor = makeScale([minColor, maxColor], [0, 150]);
    //     /**
    //      * creating a new, scaled data array
    //      */


    //     var scaledData = res.data.map(word =>(

    //       { name: word.name, weight: word.weight, color: `rgba(${word.average_sentiment < 0 ? 200 : 0},${word.average_sentiment >= 0 ? 200 : 0},0,${(Math.abs(word.average_sentiment) * .8) + .2})` }
    //       )
    //     );

    //     let options = {}
    //     options.series = [{
    //         type: "wordcloud",
    //         data: scaledData,
    //         name: "sentiment",
    //         rotation: {
    //             from: 0,
    //             to: 0
    //         }
    //         }
    //     ]
    //   this.setState({options: options});
    // })
  }

  render() {
    return (
      <div className="content">
        <Select id="id" value="name" placeholder="select project..." options={this.state.projects} onSelect={(res)=>{console.log('test', res);this.selectProject(res)}}/>
          <div className="full">
            {this.state.selectedProject &&
            <div>
              <h1>Example</h1>
              <h2>Selected term "{this.state.selectedProject.name}"</h2>
              <p>Here is a basic example of analyzed data. This word-cloud chart is showing the sentiment value of each word related to the <strong>selected keyword</strong> ('{this.state.selectedProject.name}' in this example)</p>
              <h3>Description</h3>
              <p>{this.state.selectedProject.description}</p>
              </div>
            }
        </div>
        <HighchartsReact highcharts={Highcharts} options={this.state.options} />
        <div className="full">
            <p className="small align-center"><i>*colors are representing the affirmative or negative value of the related word</i></p>
        </div>
      </div>
    );
  }
}

export default WordCloud;
