// import { Auth } from '../components/Auth';

var api = 'https://api.sentilytics.com/api';

const Service = {

    api: api,

    get(url, params, cb=null){
        // params.Auth = Auth;

        fetch(`${api}${url}`,
          {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

  

    getProducts(cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              page: 1,
              perPage: 20,
              filter: ''
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getPendingProducts(cb=null){
        fetch(`${api}/products/getPending`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              page: 1,
              perPage: 50,
              filter: ''
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    getTopProducts(params,cb=null){
        fetch(`${api}/products/top`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
              if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
          )
    },

    getProductById(params,cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    getProductBySearch(params,cb=null){
        fetch(`${api}/products/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNewProducts(cb=null){
        fetch(`${api}/products/getnewproductreleases`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    
    addToWishlist(params,cb=null){
        fetch(`${api}/users/addProduct`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    removeFromWishList(params,cb=null){
        fetch(`${api}/users/removeProduct`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getWishlist(params,cb=null){
        fetch(`${api}/users/getProducts`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getProductsByCategory(params, cb=null){

        fetch(`${api}/products/getByCategory`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(
              params
            )
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result.data.products);
                }
            },
            (error) => {

            }
        )
    },

    register(email, password, name, cb=null) {

        // return;
        fetch(`${api}/users/register`,
          {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                name: name,
                email: email,
                password: password
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    login(email, password, cb=null) {
        fetch(`${api}/users/login`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify({
              email: email,
              password: password
            })
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }

            },
            (error) => {

            }
        )
    },

    createOrder(params,cb=null) {
      fetch(`${api}/orders/create`,
        {
          method: 'post',
          headers: {'Content-Type':'application/x-www-form-urlencoded'},
          body: JSON.stringify(params)
         })
        .then(res => res.json())
        .then(
          (result) => {
              if(cb){
                  cb(result);
              }
          },
          (error) => {
          }
      )
    },
    
    addComment(params,cb=null){
        fetch(`${api}/products/addComment`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getComments(params,cb=null){
        fetch(`${api}/products/getComments`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNews(cb=null){
        fetch(`${api}/news/all`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            //body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    getNewsSingle(params,cb=null){
        fetch(`${api}/news/get`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    krckoSubscribe(params,cb=null){
        fetch(`${api}/krcko/subscribe`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {

                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

      getCart(params,cb=null){

        fetch(`${api}/orders/cart`,
          {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {

                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },

    rateProduct(params,cb=null){

        fetch(`${api}/products/rate`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(cb){
                    cb(result);
                }
            },
            (error) => {

            }
        )
    },
    
    tryRequire (path) {
      // try {
      //   return require(`${path}`);
      // } catch (err) { 
      //   return err;
      // }
      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', path, false);
      xhr.send();
      
      if (xhr.status == "404") {
          return false;
      } else {
          return true;
      }
    }
 
}

export default Service;