import React, { Component } from 'react';
import {Link} from 'react-router-dom';


const name = 'Vojno';

const arr = {true: "vojno", false: "pera"};

class HomePage extends Component {

	constructor(props) {
	    super(props);
	    this.state = {
        keywords: []
      };

	}


  componentDidMount() {
    window.scrollTo(0, 0);
    // Service.get('/entities/getEntitySentimentTop100', {projectIds: [20]}, (res)=>{
    //   this.setState({keywords: res.data});
    // })
  }





  render() {
    return (
      <div className="content">
        <div className="full">
        <h1>Sentylitics</h1>
        <h2>Sentiment analytics application</h2>
        <p>Our goal is to produce an application software (the app) that can be used across platforms to help public opinion research. The goal of the app is to analyse large amounts of text using Artificial Intelligence to acquire conclusions about any desired topic or scope that would take human beings a lifetime to read, in a few hours or days. Analysis takes out of the text the sentiment in which each word has been mentioned. As a result the app shows a number of words related to a desired topic and it&rsquo;s average sentiments rated from highly negative to positive through a word cloud. This technology is essential tool for marketing agencies, social scientist researchers, politicians, brand developers, designers, etc, that would allow them to have new insights from large amounts of data.</p>
        <p>The app will be able to identify afiirmative - positive and defensive - negative mentioned keywords in analysed texts:
        <ul>
          <li>English speaking countries;</li>
          <li>Non-English-speaking countries</li>
          <li>Search for texts on twitter and across the internet containing given keywords</li>
        </ul>
        </p>
        <p>With each client&rsquo;s app customised to search texts based on their:
        <ul>
          <li>User selected keywords;</li>
          <li>Recommended keywords by the app based on the first search results</li>
          <li>Display the search results in a user-friendly way</li>
          <li>With the list displaying each result&rsquo;s:</li>
          <li>Keyword in an interactive word cloud</li>
          <li>Word size determined by number of mentions - larger representing more mentions</li>
          <li>Sentiment in range from -1 to 1 displayed through colours</li>
          <li>Showing graphs of keywords sentiment trends through time</li>
          <li>Filtering by country, date of text creation, keywords</li>
          <li>Map showing geographical distribution of keyword sentiments using colors</li>
        </ul>
        </p>

        <p>As an example, we collected texts about coffee, <Link to="/wc" >click here</Link> to see our word cloud displaying analysed data.</p>
        </div>
      </div>
      
    )
  }
}

export default HomePage;