import React, { Component } from 'react';
import {Ajaxtest} from '../components/ajaxtest';
import {Chart} from '../components/Chart';


class Analisys extends Component {

	constructor(props) {
	    super(props);
	}



  render() {
    return (
      <div>
        <h1>Analisys</h1>
        <p>{this.props.match.params.lang}</p>
        <Chart />
      </div>
      
    )
  }
}

export default Analisys;